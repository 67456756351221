import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { ROUTES } from '../../services/exports/Constants';

export default function OnlineStatusButton() {
  const { company } = useSelector((state) => state.company);

  const isOffline = useMemo(
    () => company?.orders_paused_until && moment(company?.orders_paused_until).isAfter(moment()),
    [company?.orders_pause_reason, company?.orders_paused_until],
  );

  return (
    <a
      className="tw-w-[26px] tw-h-[26px] tw-bg-white tw-rounded-full tw-border tw-border-solid tw-border-brand-inkGrey-grey_2 tw-flex tw-justify-center tw-items-center tw-shrink-0"
      href={ROUTES.order_control}
    >
      <div
        className={classNames('tw-w-[12px] tw-h-[12px] tw-rounded-full', {
          'tw-bg-brand-primary': !isOffline,
          'tw-bg-brand-danger': isOffline,
        })}
      />
    </a>
  );
}
