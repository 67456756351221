import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CHeader, CHeaderText, CHeaderToggler } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilMenu, cilX } from '@coreui/icons';
import tw from 'twin.macro';
import { useHistory } from 'react-router-dom';
import ReduxHooks from '../store/ReduxHooks';
import { actionCreators } from '../store/actions';
import RefreshIcon from '../resources/assets/icons/RefreshIcon';
import OrdersPausedManuallyBanner from './banners/OrdersPausedManuallyBanner';
import { ROUTES } from '../services/exports/Constants';
import OnlineStatusButton from './buttons/OnlineStatusButton';

export default function Header() {
  const { company } = useSelector((state) => state.company);
  const { sidebar } = useSelector((state) => state);
  const { show } = sidebar;
  const header = useSelector((state) => state.appHeader);
  const history = useHistory();

  const renderOrdersPausedManuallyBanner = useMemo(
    () => <OrdersPausedManuallyBanner />,
    [company?.orders_pause_reason, company?.orders_paused_from, company?.orders_paused_until],
  );

  const renderCloseIcon = useMemo(
    () =>
      history.location.pathname !== ROUTES.home && (
        <CIcon size="xl" icon={cilX} className="black ml-1 cursor-pointer" onClick={() => history.push(ROUTES.home)} />
      ),
    [history.location.pathname],
  );

  const renderOnlineStatusButton = useMemo(
    () => company?.has_pausing_orders_quick_access && <OnlineStatusButton />,
    [company?.has_pausing_orders_quick_access],
  );

  return (
    <CHeader position="sticky" className="mb-3 mt-2 py-0">
      <Container className="py-2 px-2">
        <LeftContainer className="d-flex">
          <CHeaderToggler
            onClick={() => {
              ReduxHooks.dispatch(actionCreators.sidebar.toggle(!show));
            }}
          >
            {show ? (
              <CIcon size="xl" icon={cilX} className="black" />
            ) : (
              <CIcon size="xl" icon={cilMenu} className="black" />
            )}
          </CHeaderToggler>
        </LeftContainer>
        <CenterContainer className="website-text bold my-auto">
          {header?.text && <CHeaderText className="m-auto">{header?.text}</CHeaderText>}
        </CenterContainer>
        <RightContainer>
          {renderOnlineStatusButton}
          {header?.showRefresh && (
            <span className="cursor-pointer" onClick={() => window.location.reload()}>
              <RefreshIcon className="h-8 w-8" />
            </span>
          )}
          {renderCloseIcon}
        </RightContainer>
      </Container>
      {renderOrdersPausedManuallyBanner}
    </CHeader>
  );
}

const Container = tw.div`w-full grid grid-cols-6 gap-1 px-1`;
const LeftContainer = tw.div`flex items-center`;
const CenterContainer = tw.div`col-span-4 flex justify-center items-center text-center`;
const RightContainer = tw.div`flex justify-end items-center gap-2`;
